import React from 'react'
import { NotFound404 } from './NotFound404';

function Profile() {
  return (
    <div>
      <NotFound404/>
    </div>
  )
}

export {Profile}